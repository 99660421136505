<template>
  <div class="b2c-page-bg">
    <div class="img-container">
      <div class="donate-money-image">
        <b-card>
          <div class="open-credit-form-container">
            <h2>Thông tin mở thẻ</h2>
            <validation-observer ref="infoValid">
              <div class="row m-0">
                <div class="col-md-12 p-0">
                  <validation-provider #default="{ errors }" rules="required">
                    <label class="d-block font-weight-bold"
                      >Họ tên <span class="text-primary">*</span></label
                    >
                    <b-form-input
                      size="sm1"
                      maxlength="35"
                      class="donate-input"
                      ref="fullNameRef"
                      v-model="userInfo.fullName"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Nhập đúng họ và tên đăng ký mở thẻ VPBank"
                    />
                    <small class="fs-error-txt" v-if="errors.length > 0">
                      Vui lòng nhập đúng họ và tên đăng ký mở thẻ VPBank
                    </small>
                  </validation-provider>
                </div>

                <div class="col-md-12 p-0 mt-019">
                  <validation-provider
                    #default="{ errors }"
                    rules="required|min:10|VNPhone"
                  >
                    <label class="d-block font-weight-bold"
                      >Điện thoại liên lạc
                      <span class="text-primary">*</span></label
                    >
                    <b-form-input
                      type="text"
                      ref="contactPhoneRef"
                      oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                      maxlength="10"
                      size="sm1"
                      class="donate-input"
                      v-model="userInfo.contactPhone"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Nhập đúng số điện thoại đăng ký mở thẻ VPBank"
                    />
                    <small class="fs-error-txt" v-if="errors.length > 0"
                      >Vui lòng nhập đúng số điện thoại đăng ký mở thẻ
                      VPBank</small
                    >
                  </validation-provider>
                </div>

                <div class="col-md-12 p-0">
                  <validation-provider
                    #default="{ errors }"
                    rules="required|email"
                  >
                    <label class="d-block font-weight-bold"
                      >E-mail <span class="text-primary">*</span></label
                    >
                    <b-form-input
                      size="sm1"
                      maxlength="40"
                      class="donate-input"
                      ref="emailRef"
                      v-model="userInfo.email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Vui lòng nhập Email của bạn"
                    />
                    <small class="fs-error-txt" v-if="errors.length > 0"
                      >Vui lòng nhập chính xác email</small
                    >
                  </validation-provider>
                  <button
                    class="open-credit-form-btn-continue"
                    @click="onOpenCreaditCard()"
                  >
                    {{ "Mở thẻ ngay" }}
                  </button>
                  <ul>
                    <li>
                      <h4>
                        Đây là chương trình liên kết Mở thẻ tín dụng giữa VPBank
                        và iTel
                      </h4>
                    </li>
                    <li>
                      <h4>
                        Bạn sẽ được chuyển hướng sang màn hình mở thẻ tín dụng
                        của VPBank
                      </h4>
                    </li>
                    <li>
                      <h4>
                        Vui lòng liên hệ hotline VPBank: 1900545415 (1000đ/phút)
                        cho mọi vấn đề phát sinh
                      </h4>
                    </li>
                  </ul>
                </div>
              </div>
            </validation-observer>
          </div>
        </b-card>
      </div>
    </div>

    <div class="container wrap-term">
      <b-row cols="12">
        <b-col md="8" sm="12">
          <div class="img-the-le" />
          <p class="tiny-sub">
            Áp dụng cho Quý Khách mở thẻ tín dụng VPBank qua website itel.vn
            hoặc App MyiTel
          </p>
          <div class="section-promo">
            <h2>CHƯƠNG TRÌNH HOÀN TIỀN 500K</h2>
          </div>

          <div class="section-promo">
            <h2>1. Thời gian chương trình:</h2>
            <h3>
              Từ 25/05/2023 – 31/12/2023 (hoặc đến khi hết ngân sách dành cho
              chương trình).
            </h3>
          </div>

          <div class="section-promo">
            <h2>2. Đối tượng ưu đãi:</h2>
            <h3>
              Áp dụng cho khách hàng đăng ký mở mới thẻ tín dụng VPBank trên nền
              tảng website hoặc ứng dụng di động của iTel.
            </h3>
          </div>

          <div class="section-promo">
            <h2>3. Thông tin khuyến mại:</h2>
            <h3>Hoàn tiền 500,000 đồng</h3>
          </div>

          <div class="section-promo">
            <h2>4. Loại thẻ áp dụng:</h2>
            <h3>
              Các thẻ tín dụng được VPBank áp dụng trong chương trình cụ thể như
              sau:
            </h3>
          </div>

          <div class="my-2">
            <img
              class="img-bin"
              src="../../../assets/images/shop/partner/binSeri.png"
              alt="BIN"
            />
          </div>
          <div class="section-promo">
            <h2>5. Nội dung ưu đãi:</h2>
            <h3>Hoàn tiền trị giá 500.000 VNĐ cho Khách hàng.</h3>
            <h3>Điều kiện:</h3>
            <h3>
              - Khách hàng cá nhân mở mới Thẻ tín dụng lần đầu tiên và phát sinh
              tổng chi tiêu đạt 2.000.000 đồng trong vòng 30 ngày kể từ ngày mở
              thẻ.
            </h3>
            <h3>
              - Mỗi Khách hàng chỉ được áp dụng 1 lần quà tặng trong thời gian
              tổ chức Chương trình ưu đãi. Chi tiết về Chương trình hoàn tiền
              tại đây
            </h3>
            <div
              @click="$bvModal.show('modal-agree')"
              class="my-2 cursor-pointer"
            >
              <i>Xem chi tiết</i>
            </div>
          </div>
        </b-col>

        <b-col md="4" sm="12">
          <div class="space-10" />
          <div class="card p-3 mt-5">
            <p class="step-title">
              Các bước nhận thưởng: Để nhận Ưu đãi 500.000 đồng,<br />Bạn vui
              lòng chú ý và thực hiện theo hướng dẫn sau:
            </p>

            <ul class="my-2">
              <li class="step-sub">
                <b>• Bước 01:</b> Kích hoạt Thẻ sau khi mở Thẻ thành công.
              </li>
              <li class="step-sub">
                <b>• Bước 02:</b> Phát sinh chi tiêu hợp lệ 2.000.000 đồng trong
                vòng 30 ngày kể từ ngày mở Thẻ.
              </li>
            </ul>
            <p class="step-sub">
              (VPBank ghi nhận giao dịch hợp lệ và sẽ hoàn tiền về tài khoản Thẻ
              trong thời gian 60 ngày)
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-modal
      size="lg"
      body-class="p-2"
      hide-header
      hide-footer
      id="modal-agree"
      title=""
    >
      <div class="row">
        <div class="col-12 text-center">
          <p class="step-title">THỂ LỆ CHƯƠNG TRÌNH HOÀN TIỀN 500K</p>
        </div>

        <div class="pre-scrollable p-3">
          <p class="step-sub">
            1. Tên chương trình: “Chương trình Hoàn tiền trị giá 500,000 đồng
            cho khách hàng mở mới thẻ tín dụng VPBank”<br />
            2. Hình thức khuyến mại: Hoàn tiền<br />
            3. Thời gian thực hiện chương trình: từ 25/05/2023 – 31/12/2023,
            hoặc đến khi hết ngân sách dành cho chương trình, tùy thuộc sự kiện
            nào xảy ra trước.<br />
            4. Kênh áp dụng/Địa điểm áp dụng ưu đãi: Áp dụng cho khách hàng đăng
            ký mở mới thẻ tín dụng VPBank trên nền tảng website hoặc ứng dụng di
            động của Itel<br />
            5. Hàng hóa, dịch vụ khuyến mại: Các dòng thẻ tín dụng của VPBank
            được phân loại và liệt kê ở mục 9.1.<br />
            6. Hàng hoá dùng để khuyến mại: Hoàn tiền 500,000 đồng <br />7.
            Khách hàng của chương trình khuyến mại: Khách hàng cá nhân thỏa điều
            kiện của chương trình.<br />
            8. Tổng giá trị giải thưởng: 125,000,000 đồng ( đã bao gồm VAT)<br />
            9. Nội dung chi tiết của Chương trình khuyến mại:<br />
            9.1. Loại thẻ áp dụng: Các thẻ tín dụng được VPBank áp dụng trong
            chương trình cụ thể như sau:
            <img
              class="img-bin"
              src="../../../assets/images/shop/partner/binSeri.png"
              alt="BIN"
            />
            <br />
            9.2 Nội dung ưu đãi <br />
            - Hoàn tiền trị giá 500.000 VNĐ cho Khách hàng mở mới thẻ tín dụng
            VPBank và có tổng chi tiêu đạt 2.000.000 đồng trong vòng 30 ngày kể
            từ ngày mở thẻ.<br />
            - Số lượt ưu đãi: 250 phần quà xuyên suốt chương trình. <br />
            - Mỗi Khách hàng (CIF) chỉ có thể được áp dụng 1 lần quà tặng trong
            thời gian tổ chức Chương trình ưu đãi. <br />
            9.3. Điều kiện chương trình <br />
            a. Điều kiện khách hàng: <br />
            - Áp dụng cho khách hàng mở mới thẻ tín dụng (Thẻ chính) lần đầu
            tiên tại VPBank (không áp dụng thẻ mở mới thứ hai trở đi, thẻ ảo,
            thẻ mở lại do mất mát, thất lạc, hư hỏng,...) qua kênh bán tại nền
            tảng website và ứng dụng di động của đối tác Itel.<br />
            - Không áp dụng cho CBNV VPBank <br />
            - Thời gian thẻ phát hành thành công trên hệ thống sẽ được sử dụng
            để xác định khách hàng thỏa điều kiện sớm nhất của chương trình<br />
            - Trường hợp có nhiều hơn 1 khách hàng có thời gian phát hành thẻ
            thành công trên hệ thống giống nhau, sẽ căn cứ theo tổng chi tiêu
            thực hiện thành công trong 30 ngày kể từ ngày mở thẻ. KH có tổng chi
            tiêu lớn hơn sẽ được ưu tiên nhận ưu đãi. Ví dụ: Khách hàng A và B
            có cùng phát hành thẻ ở Mục 9.1 là ngày 31/12. Thỏa điều kiện chi
            tiêu và được xếp hạng theo thứ tự lần lượt là 350 và 351. Vì số
            lượng giới hạn 350 phần quà cho Khách hàng mở thẻ, khi xét tới giao
            dịch hợp lệ trong vòng 30 ngày kể từ 31/12, khách hàng A có tổng chi
            tiêu là 20 triệu VND, khách hàng B có tổng chi tiêu là 15 triệu.
            Khách hàng A sẽ được ưu tiên nhận ưu đãi<br />
            - Chương trình không áp dụng đồng thời các chương trình ưu đãi mở
            thẻ khác.<br />
            b. Giao dịch hợp lệ:<br />
            - Giao dịch thanh toán hàng hóa dịch vụ phục vụ mục đích tiêu dùng
            cá nhân hợp pháp. Tổng doanh số giao dịch chỉ tính giao dịch của thẻ
            Chính (thẻ vật lý và thẻ phi vật lý). <br />
            - Giao dịch thực hiện trong 30 ngày kể từ ngày mở thẻ trên hệ thống
            và được ghi nhận thành công về hệ thống VPBank trong vòng 40 ngày kể
            từ ngày mở thẻ trên hệ thống. <br />
            - Giao dịch không hợp lệ để xét tổng chi tiêu bao gồm:<br />
            • Giao dịch hủy/ hoàn lại <br />
            • Giao dịch chi tiêu thanh toán điện, nước, xăng dầu, viễn thông,
            truyền hình, internet,…<br />
            • Giao dịch chi tiêu trên NEO<br />
            • Giao dịch rút tiền mặt và giao dịch phí lãi phát sinh<br />
            • Giao dịch nghi ngờ rút tiền mặt hoặc giao dịch gian lận.<br />
            • Giao dịch không có mục đích tiêu dùng hợp pháp.<br />
            c. Quy định Hoàn tiền:<br />
            - Thời gian hoàn tiền: Thời gian hoàn tiền sẽ diễn ra sau 60 ngày kể
            từ ngày Khách hàng thỏa điều kiện chương trình, VPBank tổng kết và
            danh sách khách hàng chốt dữ liệu vào ngày cuối cùng của mỗi tháng
            (T). Thời hạn cuối cùng VBank có trách nhiệm thông báo và ghi nhận
            tiền hoàn cho Khách hàng là ngày cuối cùng của tháng T+2. Ví dụ:
            Danh sách khách hàng mở thẻ mới từ 01/04 đến 30/04 sẽ được chốt dữ
            liệu thỏa mãn các điều kiện vào ngày 31/05 và sẽ được VPBank hoàn
            tiền vào ngày 30/06 <br />
            - Thời hạn cuối cùng VBank có trách nhiệm thông báo và ghi nhận hoàn
            tiền cho Khách hàng là sau 90 ngày kể từ ngày kết thúc chương trình
            <br />
            - Toàn bộ số tiền hoàn mà Khách hàng được nhận sẽ VPBank hoàn trực
            tiếp về Hợp đồng thẻ của Khách hàng.<br />
            - Trong những trường hợp có lỗi hệ thống, VPBank sẽ thông báo cho
            khách hàng thời gian ghi nhận hoàn tiền qua email hoặc hình thức
            khác tùy VPBank quyết định tại từng thời kỳ.<br />
            - Tại thời điểm VPBank xét hoàn tiền, Khách hàng đảm bảo trạng thái
            thẻ tham gia chương trình là đang hoạt động (Card ok). VPBank sẽ
            không có trách nhiệm xét ưu đãi cho các khách hàng đã đóng thẻ, đóng
            tài khoản, chậm thanh toán hoặc có trạng thái thẻ khác với Card ok.
            <br />
            - Số tiền hoàn mà Khách hàng nhận được không được quy đổi ra tiền
            mặt hoặc các thành phẩm khác trong mọi trường hợp. <br />
            - Khách hàng không được sử dụng, mua bán, trao đổi, tặng cho, chuyển
            khoản, chuyển nhượng số tiền hoàn mà mình được ghi nhận (chưa thực
            hiện yêu cầu hoàn tiền thành công).<br />
            10. Một số quy định khác:<br />
            - Nếu được Khách hàng đồng ý, VPBank được phép sử dụng hình ảnh, tư
            liệu và các thông tin liên quan đến người trúng thưởng cho mục đích
            quảng cáo, khuyến mãi của VPBank và trên các phương tiện thông tin
            đại chúng mà không phải trả bất cứ chi phí nào thêm cho người nhận
            thưởng.<br />
            - Giao dịch chi tiêu hợp lệ được thực hiện bởi Chủ thẻ chính và phải
            là các giao dịch mua bán thực tế và được phép theo quy định của pháp
            luật Việt Nam.<br />
            - VPBank có toàn quyền từ chối trao quà tặng/dịch vụ cho bất kỳ
            Khách hàng nào mà VPBank cho là gian lận, giả mạo, hoặc không đúng
            bản chất giao dịch thanh toán hàng hóa, dịch vụ phục vụ mục đích
            tiêu dùng cá nhân hợp pháp qua POS và Internet tại các ĐVCNY hoặc vi
            phạm bất kỳ Điều khoản và Điều kiện nào của Chương trình<br />
            - VPBank có thể liên lạc với Khách hàng qua số điện thoại/ email đã
            đăng ký với VPBank để xác minh một số thông tin và yêu cầu bổ sung
            giấy tờ nếu cần thiết.<br />
            - Trong trường hợp Khách hàng có khiếu nại Khách hàng cần thực hiện
            khiếu nại (nếu có) qua số điện thoại 1900 54 54 15 của Phòng Chăm
            Sóc Khách Hàng 24/7 – VPBank trễ nhất sau thời gian kết thúc chương
            trình 80 ngày để được VPBank giải quyết.<br />
            - Trong mọi trường hợp, VPBank sẽ không giải quyết khiếu nại khi
            VPBank đã trao thưởng cho Khách hàng theo đúng thể lệ tối đa sau 90
            ngày kể từ ngày kết thúc chương trình <br />
            - VPBank sẽ không nhận/giải quyết mọi khiếu nại của chương trình tối
            đa sau 90 ngày kể từ ngày kết thúc chương trình<br />
            - Trường hợp khách hàng chi tiêu đúng mã ngành nhưng bị loại
            trừ/không được nhận chi tiêu theo đúng thể lệ mà VPBank công bố,
            khách hàng cần cung cấp hóa đơn mua hàng/sử dụng dịch vụ tại các
            ĐVCNT (không chấp nhận hóa đơn cà thẻ) chứng minh giao dịch và có
            mục đích tiêu dùng hợp pháp.<br />
            - Trên hóa đơn cung cấp cho VPBank cần ghi rõ địa chỉ mua hàng (Tên
            cửa hàng/ĐVCNT, địa điểm gồm số nhà/đường phố/quận, huyện/thành phố,
            các dịch vụ đã mua/sử dụng tại cửa hàng/ĐVCNT). VPBank kiểm tra,
            thực nghiệm thông tin mà khách hàng cung cấp và có quyền chấp nhận
            hoặc từ chối bổ sung tặng quà/ hoàn tiền sau khi kiểm tra. Quyết
            định của VPBank là quyết định cuối cùng trong mọi trường hợp.<br />
            - VPBank, những công ty liên kết của VPBank, giám đốc, nhân viên và
            người đại diện tương ứng của VPBank và những công ty liên kết này sẽ
            không chịu trách nhiệm cho bất kỳ mất mát, thiệt hại nào (bao gồm
            nhưng không giới hạn ở những mất mát gián tiếp và phái sinh) hoặc
            bất kỳ tai nạn cá nhân nào xảy ra từ việc tham gia vào Chương trình
            hoặc do việc tiếp nhận hoặc sử dụng quà tặng/dịch vụ, ngoại trừ
            những trách nhiệm được luật pháp quy định. VPBank vẫn sẽ tiếp nhận
            và hỗ trợ xử lý khiếu nại từ Khách hàng theo quy định của pháp
            luật.<br />
            - Với việc chấp nhận hay tiếp nhận quà tặng/dịch vụ, Khách hàng hay
            người nhận đồng ý đảm bảo và sẽ giữ cho VPBank, những công ty liên
            kết của VPBank, giám đốc, nhân viên và người đại diện của VPBank và
            những công ty liên kết này không bị thiệt hại bởi những khiếu kiện,
            hành động, khởi kiện, xét xử, thiệt hại, tổn thất, chi phí phát sinh
            hay trách nhiệm nào dành cho VPBank, những công ty liên kết của
            VPBank, và giám đốc, nhân viên và người đại diện của VPBank và những
            công ty liên kết này do có liên quan đến việc tham gia vào Chương
            trình hoặc tiếp nhận hoặc sử dụng Quà tặng/dịch vụ. <br />
            - Bằng việc tham gia Chương trình Khuyến mại này, Khách Hàng chấp
            thuận tất cả các Điều khoản và Điều Kiện của Chương trình Khuyến mại
            được liệt kê trên. Trong trường hợp có tranh chấp, VPBank sẽ xem xét
            phương án giải quyết tốt nhất cho Khách hàng theo quy định của pháp
            luật. <br />
            - VPBank sẽ không chịu trách nhiệm đối với việc Chủ thẻ không nhận
            được thông báo do thay đổi địa chỉ liên hệ, email hay số điện thoại
            mà chưa cập nhật cho VPBank, hay việc thư Thông báo bị thất lạc, hưu
            hỏng hoặc mất cắp trong quá trình gửi.<br />
            - KH phải chịu mọi chi phí phát sinh (nếu có) liên quan đến việc sử
            dụng quà tặng/dịch vụ từ Chương trình bao gồm không giới hạn khoản
            thuế thu nhập cá nhân (nếu có).<br />
            - Mọi quyết định của VPBank liên quan đến các chương trình khuyến
            mãi, bao gồm thay đổi loại thẻ, điều kiện chi tiêu, quà tặng, kết
            quả Khách hàng nhận ưu đãi trong suốt Chương trình này có thể thay
            đổi tại từng thời điểm theo toàn quyền quyết định của VPBank sau khi
            đã thực hiện các thủ tục cần thiết theo quy định của pháp luật.<br />
            - Trong trường hợp chương trình có mâu thuẫn với bất kì quy định
            pháp luật hay chỉ thị của bất kỳ cơ quan nhà nước nào, chương trình
            sẽ được thay đổi, hủy bỏ hay sửa đổi để phù hợp với quy định của
            luật, chỉ thị đó.<br />
          </p>
        </div>
        <div class="col-12 text-center pt-0 mt-0 mb-2">
          <button
            class="open-form-btn-close"
            @click="$bvModal.hide('modal-agree')"
          >
            Đóng
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BCardHeader,
  BFormInput,
  BCardBody,
  BCardFooter,
  BRow,
  BCol,
  BModal,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, min, email } from "@validations";
import useJwt from "@/auth/jwt/useJwt";

extend("VNPhone", (value) => {
  const regexPhoneNumber = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
  return value.match(regexPhoneNumber) ? true : false;
});

export default {
  components: {
    BCard,
    BCardText,
    BCardHeader,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BRow,
    BCol,
    BCardBody,
    BCardFooter,
    BModal,
  },
  data() {
    return {
      required,
      min,
      email,
      step: 1,
      userInfo: {
        fullName: "",
        contactPhone: "",
        email: "",
      },
      errorInfo: {
        isError: false,
        infoError: "",
      },
      isSuccess: false,
    };
  },
  methods: {
    openVPBank() {
      const a = document.createElement("a");
      a.href =
        "https://cards.vpbank.com.vn/?utm_campaign=JarvisCustCC.Partner&utm_source=iTel&utm_medium=iTelWeb";
      a.target = "_blank";
      a.click();
    },
    async onOpenCreaditCard() {
      var validate = await this.$refs.infoValid.validate().then((success) => {
        return success;
      });

      await useJwt
        .post("saveInforCusVpBank", this.userInfo)
        .then((response) => {
          if (response.data.code !== 200) {
            this.errorInfo.isError = true;
            this.isSuccess = false;
            this.errorInfo.infoError = response.data.message;
          } else {
            const a = document.createElement("a");
            a.href =
              "https://cards.vpbank.com.vn/?utm_campaign=JarvisCustCC.Partner&utm_source=iTel&utm_medium=iTelWeb";
            a.click();
          }
        });
    },
    onClickError() {
      this.errorInfo.isError = false;
      if (this.errorInfo.infoError === "Họ tên không được để trống") {
        this.$refs["fullNameRef"].focus();
      } else if (
        this.errorInfo.infoError ===
          "Điện thoại liên lạc không được để trống" ||
        this.errorInfo.infoError === "Điện thoại liên lạc không đúng định dạng"
      ) {
        this.$refs["contactPhoneRef"].focus();
      } else {
        this.$refs["emailRef"].focus();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.img-container {
  width: 100vw;
  background-image: url("../../../assets/images/shop/partner/itelXvpbank.png");
  height: 80vh;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: right;
}

.donate-input::placeholder {
  font-size: 13px;
}
.tiny-sub {
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 12px;
  color: #717171;
}

.section-promo {
  margin-top: 2rem;
  margin-bottom: 2rem;
  h2 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #000000;
  }
  h3 {
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
  h4 {
    font-size: 14px;
    line-height: 20px;
  }
  i {
    color: #ed1f24;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    margin-top: 12px;
    text-decoration: underline;
  }
}

.donate-money-image {
  width: 30%;
  margin-right: 10%;
}

.open-credit-form-btn-continue {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 11px;
  color: #ffffff;
  width: 100%;
  background: #ed1f24;
  border-radius: 45px;
  padding: 17.75px 0;
  margin-top: 2rem;
  margin-bottom: 2rem;
  align-self: "center";
}
.open-form-btn-close {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 11px;
  text-align: center;
  color: #ffffff;
  background: #ed1f24;
  border-radius: 45px;
  width: 20%;
  padding: 17.75px 0;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.img-bin {
  width: 310px;
  height: 196px;
}
.open-credit-form-container {
  margin-bottom: 10px;
  /* width: 30%; */
  height: 100%;
  h2 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    color: #000000;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
  }

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
  }
  ul {
    margin-left: 1rem;
    list-style-type: disc;
  }
}
.step-title {
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.step-sub {
  font-size: 14px;
  line-height: 23px;
  color: #000000;
}
.space-10 {
  height: 10%;
}
.pre-scrollable {
  max-height: 320px;
  overflow-y: scroll;
}
.img-the-le {
  background-image: url("../../../assets/images/shop/vpbank-khuyen-mai.svg");
  width: 60%;
  height: 46px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
@media screen and (max-width: 593px) {
  .img-container {
    background-image: url("../../../assets/images/shop/partner/bg_vp_mb.svg");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    position: relative;
  }
  .donate-money-image {
    width: 90%;
    margin-right: 5%;
    margin-left: 5%;
    bottom: -200px;
    position: absolute;
  }

  .tiny-sub {
    font-size: 8px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 12px;
    padding-left: 8px;
    padding-right: 8px;
    color: #717171;
  }

  .section-promo {
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    h2 {
      font-size: 12px;
      line-height: 20px;
      font-weight: 600;
      color: #000000;
    }
    h3 {
      font-size: 12px;
      line-height: 20px;
      color: #000000;
    }
    h4 {
      font-size: 12px;
      line-height: 20px;
    }
    i {
      color: #ed1f24;
      font-size: 12px;
      line-height: 20px;
      font-weight: 600;
      margin-top: 12px;
      text-decoration: underline;
    }
  }
  .wrap-term {
    margin-top: 50%;
  }
  .space-10 {
    height: 0%;
  }
  .step-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  .step-sub {
    font-size: 12px;
    line-height: 23px;
    color: #000000;
  }
  .donate-input::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  .open-credit-form-btn-continue {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
    width: 100%;
    background: #ed1f24;
    border-radius: 45px;
    padding: 15.75px 0;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .open-form-btn-close {
    width: 100%;
  }
  .img-the-le {
    background-image: url("../../../assets/images/shop/vpbank-khuyen-mai-m.svg");
    width: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 28px;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 768px) {
}
</style>